import {NavLink} from 'react-router-dom'

const MobileNav = props =>{


    return (
        <footer>
            <section className="mobile-footer d-flex justify-content-around py-3 d-lg-none">
                <NavLink to = '/admin' className="outer-shadow" activeClassName = "active" href="">
                    <i className="fas fa-tachometer-alt gw-accent-color"></i>
                </NavLink>
                <NavLink to = "/project" className="outer-shadow" activeClassName = "active" href="">
                    <i className="fas fa-folder"></i>
                </NavLink>
                <NavLink to = "/generate" className="outer-shadow" activeClassName = "active" href="">
                    <i className="fas fa-recycle"></i>
                </NavLink>
                <NavLink to  = "/analytics" className="outer-shadow" activeClassName = "active" href="">
                    <i className="fas fa-chart-line"></i>
                </NavLink>
                <NavLink to  = "/theme" className="outer-shadow" activeClassName = "active" href="">
                    <i className="fas fa-cog"></i>
                </NavLink>
            </section>
        </footer>
    )
}

export default MobileNav;