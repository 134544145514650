import { useSelector } from "react-redux";
import { submitRequisition } from "../actions/dashboardActions";
import { currencyCodes, truncate } from "../config/helpers";
import Projects from "./subcomponents/Projects";
import ChartsAcocunt from "./subcomponents/ChartsAccount";
import ReceiptUploader from "./ReceiptUploader";
import ReceiptItem from "./subcomponents/ReceiptItem";
import FormErrorList from "./subcomponents/FormErrorList";
import { Formik, Form } from "formik";
import * as dayjs from "dayjs";
import * as accounting from "accounting";
import ReactTooltip from "react-tooltip";
import MultiSelectComponent from "./subcomponents/MultiSelectComponent";
import "../css/multiselect.css";
import {
    admin_arr,
    admin_f_check,
    budget_holders_arr,
} from "../config/constants";

const RequisitionForm = (props) => {
    let auth = useSelector(({ auth }) => auth);
    let { projects, systemStats } = useSelector(
        ({ projects, systemStats }) => ({
            projects,
            systemStats,
        })
    );
    const current_user_email = auth.email;
    let claims = auth.claims;
    console.log("mycliams", claims);
    let id = props.id;
    let req = projects.find((req) => req.id === id);
    let date = dayjs(Math.abs(req.date)).format("D MMM, YYYY");
    let projectAccount = req.projectChargedTo
        ? req.projectChargedTo.account
        : { accountName: "", accountNumber: "", bankName: "" };
    let formikInitialValues = {
        account: { ...projectAccount, projectName: req.projectName || "" },
        projecthChargedTo: req.projectChargedTo || "",
        receipts: req.receipts || [],
        budgeLineItem: req.budgeLineItem || "",
        projectId: "",
        status: req.status || "",
        codes: req.codes || "",
        budgetLineItems: req.budgetLineItems || [],
    };

    return (
        <div className="outer-shadow mt-5 pt-5 req-cont px-5">
            <div className="d-flex justify-content-end">
                <button
                    to="/admin"
                    className="align-self-end gw-info-color"
                    onClick={props.closeModal}
                >
                    <i className="fas fa-arrow-left gw-info-color"></i>
                </button>
            </div>

            <div className="mt-5 px-lg-5">
                <div className="d-flex justify-content-center my-3">
                    <h3 className="heading-5 mx-auto">{req.type}</h3>
                </div>
                <section className="d-flex justify-content-between">
                    <div>
                        <h6 className="heading-5">Name</h6>
                        <p>{req.user.name}</p>
                    </div>
                    <div>
                        <h6 className="heading-5">Date</h6>
                        <p>{date}</p>
                    </div>
                    <div>
                        <h6 className="heading-5">Type</h6>
                        <p>{req.type || "Fund Req"}</p>
                    </div>
                </section>
                <section className="mt-4 pt-4">
                    <h6 className="heading-5">Description</h6>
                    <p>{req.title}</p>
                </section>
                <section className="d-flex justify-content-between mt-4 pt-4 beneficiary">
                    <div>
                        <h6 className="heading-5">Name of Beneficiary</h6>
                        <p>{req.accountName}</p>
                    </div>
                    <div>
                        <h6 className="heading-5">Account Number</h6>
                        <p>{req.accountNumber}</p>
                    </div>
                    <div>
                        <h6 className="heading-5">Bank</h6>
                        <p>{req.bankName}</p>
                    </div>
                </section>
                <section className="mt-4 pt-4">
                    <h6 className="heading-6 mb-3">Invoices</h6>
                    <div className="d-flex invoices">
                        {req.invoices &&
                            req.invoices.map((invoice) => (
                                <div className="outer-shadow mr-4 d-flex align-items-center">
                                    <span className="mr-3">{invoice.name}</span>
                                    <a
                                        className="gw-info-color"
                                        href={invoice.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <i className="fas fa-eye gw-info-color"></i>
                                    </a>
                                </div>
                            ))}
                    </div>
                </section>
                <section className="mt-4 pt-4 row">
                    <div className="col-lg-12">
                        <h6 className="heading-6 mb-3">Item List</h6>
                        {req.items.map((item) => (
                            <div className="outer-shadow row mt-3 p-3">
                                <div className="flex-grow-1 col-lg-5 col-12">
                                    <label className="item-label">
                                        Description
                                    </label>
                                    <p data-tip={item.title} className="m-0">
                                        {/* {item.title ? item.title.trunc(45) : ""} */}
                                        {item.title
                                            ? truncate(item.title, 45)
                                            : ""}
                                    </p>
                                </div>
                                <div className="col-lg-2 col-12">
                                    <label className="item-label">Amount</label>
                                    <h5 className="m-0 heading-5">
                                        {item.amount
                                            ? accounting.formatNumber(
                                                  item.amount,
                                                  2
                                              )
                                            : ""}
                                    </h5>
                                </div>

                                <div className="col-lg-5 col-2 flex-grow-1">
                                    <label className="item-label">
                                        Budget Line
                                    </label>
                                    <p className="m-0">{item.code || ""}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <div className="mt-4 pt-4 row figures px-1">
                    <div className="col-6">
                        <h6 className="heading-6">Total</h6>
                        <p className="m-0">
                            {accounting.formatMoney(
                                req.total,
                                currencyCodes[req.currency]
                            )}
                        </p>
                    </div>
                    <div className="col-6">
                        <h6 className="heading-6">Amount in Words</h6>
                        <p className="m-0">{req.amountInWords}</p>
                    </div>
                </div>

                <Formik
                    initialValues={formikInitialValues}
                    onSubmit={async (values) => {
                        console.log(values);
                        try {
                            await submitRequisition(
                                values,
                                req,
                                auth,
                                systemStats
                            );
                            props.closeModal();
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    validate={(values) => {
                        let errors = {};
                        if (req.type !== "Petty Cash") {
                            if (
                                values.account.accountNumber === "" ||
                                values.projectName === ""
                            )
                                errors.projectChargedTo =
                                    "You have to select a project to charge requisition to";
                        }
                        return errors;
                    }}
                >
                    {({
                        values,
                        errors,
                        isSubmitting,
                        touched,
                        handleChange,
                        setFieldValue,
                        submitForm,
                    }) =>
                        req.type === "Petty Cash" ? (
                            <button
                                onClick={() => {
                                    setFieldValue("status", "approved");
                                    submitForm();
                                }}
                                className="btn btn-md btn-gw-success mt-5"
                                type="button"
                            >
                                Approve
                            </button>
                        ) : (
                            <Form>
                                <section className="mt-4 pt-4 codes">
                                    <h6 className="heading-6">Codes</h6>
                                    <ChartsAcocunt />
                                </section>
                                <MultiSelectComponent
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                                <section className="mt-4 pt-4 source">
                                    <Projects />
                                    <div className="d-flex justify-content-between mt-4">
                                        <div>
                                            <h6 className="heading-6">
                                                Source Account
                                            </h6>
                                            <p>{values.account.projectName}</p>
                                        </div>
                                        <div>
                                            <h6 className="heading-6">
                                                Source Account Number
                                            </h6>
                                            <p>
                                                {values.account.accountNumber}
                                            </p>
                                        </div>
                                        <div>
                                            <h6 className="heading-6">Bank</h6>
                                            <p>{values.account.bankName}</p>
                                        </div>
                                    </div>
                                </section>
                                <section className="mt-4 pt-4">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <ReceiptUploader
                                                newIndex={
                                                    values.receipts.length
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-12 receipt-list">
                                            <h6 className="my-3">
                                                Receipt List
                                            </h6>
                                            {values.receipts.map((recpt, i) => (
                                                <ReceiptItem
                                                    name={`receipts.${i}.name`}
                                                    id={recpt.id}
                                                    url={recpt.url}
                                                    index={i}
                                                    value={`${recpt.name}`}
                                                    handleChange={handleChange}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </section>
                                <section className="mt-3">
                                    {Object.keys(errors).length !== 0 &&
                                    Object.keys(touched).length !== 0 ? (
                                        <FormErrorList errors={errors} />
                                    ) : (
                                        ""
                                    )}
                                </section>
                                <section className="mt-2 pt-2 pb-4">
                                    <h5 className="bg-success p-2 text-dark">
                                        You can no longer check requisitions
                                        here. Please check on the new Gwapp.
                                    </h5>
                                    <p>
                                        <em>
                                            <strong>NOTE:&nbsp;</strong>If you
                                            do not see some latest requisitions,
                                            keep calm and check after a while!
                                            They are being migrated.
                                        </em>
                                    </p>
                                    <div className="container check-buttons">
                                        {/* <div className="">
                                            {(claims.budgetHolder ||
                                                budget_holders_arr.includes(
                                                    current_user_email
                                                )) && (
                                                <div className="submit-btn">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "status",
                                                                "holderCheck"
                                                            );
                                                            submitForm();
                                                        }}
                                                        className=" btn btn-md btn-gw-primary"
                                                    >
                                                        <i className="fas fa-check mr-2"></i>
                                                        Budget Holder Check
                                                    </button>
                                                </div>
                                            )}
                                            {(claims.admin ||
                                                admin_arr.includes(
                                                    current_user_email
                                                ) ||
                                                admin_f_check.includes(
                                                    current_user_email
                                                )) && (
                                                <div className="submit-btn">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "status",
                                                                "checked"
                                                            );
                                                            submitForm();
                                                        }}
                                                        className=" btn btn-md btn-gw-danger"
                                                    >
                                                        <i className="fas fa-check mr-2"></i>
                                                        Finance Check
                                                    </button>
                                                </div>
                                            )}
                                            {(claims.reviewer ||
                                                admin_arr.includes(
                                                    current_user_email
                                                )) && (
                                                <div className="submit-btn">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "status",
                                                                "reviewed"
                                                            );
                                                            submitForm();
                                                        }}
                                                        className=" btn btn-md btn-gw-info"
                                                    >
                                                        <i className="fas fa-check mr-2"></i>
                                                        Reviewer Check
                                                    </button>
                                                </div>
                                            )}
                                            {claims.master && (
                                                <div className="submit-btn">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "status",
                                                                "approved"
                                                            );
                                                            submitForm();
                                                        }}
                                                        className=" btn btn-md btn-gw-success"
                                                    >
                                                        <i className="fas fa-check mr-2"></i>
                                                        Approve
                                                    </button>
                                                </div>
                                            )}
                                            {(claims.master ||
                                                claims.reviewer) && (
                                                <div className="submit-btn">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "status",
                                                                "uploaded"
                                                            );
                                                            submitForm();
                                                        }}
                                                        className=" btn btn-md btn-gw-primary-outline"
                                                    >
                                                        <i className="fas fa-upload mr-2"></i>
                                                        Uploaded for Payment
                                                    </button>
                                                </div>
                                            )}
                                            {(claims.master ||
                                                claims.reviewer) && (
                                                <div className="submit-btn">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "status",
                                                                "paymentMade"
                                                            );
                                                            submitForm();
                                                        }}
                                                        className=" btn btn-md btn-gw-success"
                                                    >
                                                        <i className="fas fa-check mr-2"></i>
                                                        Payment Made
                                                    </button>
                                                </div>
                                            )}
                                        </div> */}
                                    </div>
                                </section>
                            </Form>
                        )
                    }
                </Formik>
            </div>
            <ReactTooltip />
        </div>
    );
};

export default RequisitionForm;
