import firebase from 'firebase';
import { PROJECT_CHARGE, FETCH_ACCOUNTS, CHARTS_CODES, USER_LIST, BUDGET_CODES } from './types';
import { cloudURL, sendMail } from '../config/helpers'
import * as dayjs from 'dayjs';
import axios from 'axios';
const tempuser = null;
const budgetCodes = require('../config/budgetCodes.json');

export const fetchRequisitions = (startDate, endDate, period) => {
    console.log(startDate);
    let dbRef = firebase.database().ref('requisitions').orderByChild('time');
    if (period !== '' && period !== 'all') {
        switch (period) {
            case 'week':
                let wstart = 0 - dayjs().day(1).valueOf();
                let wend = 0 - dayjs().day(5).valueOf();
                console.log(wstart, wend);
                return dbRef.startAt(wend).endAt(wstart).limitToFirst(50);

            case 'month':
                let start = 0 - dayjs().date(1).valueOf();
                let end = 0 - dayjs().date(30).valueOf();
                console.log(start, end);
                return dbRef.startAt(end).endAt(start).limitToFirst(50);

            default:
                break;
        }

    }
    else {
        if (startDate !== '') {
            return dbRef.startAt(startDate).limitToFirst(50);
        }
        if (endDate !== '') {
            return dbRef.endAt(endDate).limitToFirst(50);
        }
    }

    return dbRef.limitToFirst(50)

}


export const fetchProjectsChargeTo = (dispatch) => {
    firebase.database().ref('projects').once('value', (snapshot) => {
        let projects = [];
        snapshot.forEach(snap => {
            let proj = snap.val();
            proj.id = snap.key;
            projects.push(proj);
        });
        dispatch({ type: PROJECT_CHARGE, payload: projects });
    })
}

export const fetchChateredCodes = (dispatch) => {
    firebase.database().ref('codes').once('value', (snapshot) => {
        let chartsCodes = [];
        snapshot.forEach((snap) => {
            let code = `${snap.key} - ${snap.val()}`;
            chartsCodes.push(code);
        });
        dispatch({ type: CHARTS_CODES, payload: chartsCodes })

    });
}


export const submitRequisition = async (formvalues, oldReq, auth, systemStats) => {
    let updates = {};
    let { status, receipts, projectChargedTo, budgetLineItems, account, projectId } = formvalues;
    let userId = oldReq.userId;
    if (oldReq.type !== 'Petty Cash') {
        let newReq = {
            ...oldReq, budgetLineItems,
            status, receipts, projectId, projectName: account.projectName,
            sourceAccountNumber: account.accountNumber, sourceBankName: account.bankName,
            codes: formvalues.codes
        }
        if (projectChargedTo) newReq.projectChargedTo = projectChargedTo;

        switch (status) {
            case "holderCheck":
                newReq.holderCheck = auth.myuser;
                newReq.hoderCheckDate = 0 - new Date().getTime();
                break;
            case "checked":
                newReq.checkedBy = auth.myuser;
                newReq.checkedDate = 0 - new Date().getTime();
                break;
            case "reviewed":
                newReq.reviewedBy = auth.myuser;
                newReq.reviewDate = 0 - new Date().getTime();
                break;
            case "uploaded":
                newReq.uploadedBy = auth.myuser;
                newReq.uploadDate = 0 - new Date().getTime();
                break;
            case "paymentMade":
                newReq.paidBy = auth.myuser;
                newReq.paymentDate = 0 - new Date().getTime();
                break;
            case "approved":
                newReq.countRef = systemStats && systemStats.requisitionCount;
                let num = systemStats.pvNumbers[account.projectName] ? systemStats.pvNumbers[account.projectName] + 1 : 1;
                let statsUpdate = {};
                statsUpdate[`systemStats/pvNumbers/${account.projectName}`] = num;
                statsUpdate[`systemStats/requisitionCount`] = systemStats.requisitionCount + 1;
                await firebase.database().ref().update(statsUpdate);
                newReq.pvNumber = num;
                newReq.approvedBy = auth.myuser;
                newReq.approvedDate = 0 - new Date().getTime();
                break;

            default:
                break;
        }


        updates[`requisitions/${newReq.id}`] = newReq
        updates[`userRequisitions/${userId}/${newReq.id}`] = newReq;




    }
    else {
        let newReq = { ...oldReq, status, budgetLineItems };
        newReq.approvedBy = auth.myuser;
        newReq.checkedBy = auth.myuser;
        newReq.approvedDate = 0 - new Date().getTime();
        newReq.checkedDate = 0 - new Date().getTime();
        updates[`requisitions/${newReq.id}`] = newReq
        updates[`userRequisitions/${userId}/${newReq.id}`] = newReq;
    }


    return firebase.database().ref().update(updates);


}

export const sendBackRequisition = (oldReq, auth) => {
    let newReq = { ...oldReq, status: "sentBack" };
    let userId = tempuser || oldReq.userId;
    let updates = {};
    updates[`requisitions/${newReq.id}`] = newReq;
    updates[`userRequisitions/${userId}/${newReq.id}`] = newReq;
    sendMail(oldReq, auth);
    return firebase.database().ref().update(updates);

}

export const fetchAccounts = async (dispatch) => {
    firebase.database().ref('accounts').on('value', (snapshot) => {
        let accounts = [];
        snapshot.forEach((snap) => {
            let account = snap.val();
            account.id = snap.key;
            accounts.push(account);
        });
        dispatch({ type: FETCH_ACCOUNTS, payload: accounts })
    })
}

export const fetchBudgets = async (dispatch) => {
    firebase.database().ref('budgetCodes').on('value', (snapshot) => {
        let budgets = [];
        snapshot.forEach((snap) => {
            let budget = snap.val();
            budget.id = snap.key;
            budgets.push(budget);
        });
        dispatch({ type: BUDGET_CODES, payload: budgets })
    })
}

export const fetchUserList = (dispatch) => {
    firebase.database().ref('userList').once('value', (snapshot) => {
        let userList = [];
        snapshot.forEach((snap) => {
            let user = snap.val();
            user.id = snap.key;
            userList.push(user);

        })
        console.log('userlist ', userList)
        dispatch({ type: USER_LIST, payload: userList })
    })
}

export const analyticsSearch = async (req) => {
    console.log({ req })
    req.start = req.startDate ? 0 - new Date(req.startDate).getTime() : '';
    req.end = req.endDate ? 0 - new Date(req.endDate).getTime() : '';


    let result = await axios.post(cloudURL + "searchRequisition", req);

    console.log(result);
    return result.data;
}


export const putBudgetCodes = async () => {

    try {
        await firebase.database().ref(`budgetCodes`).set(budgetCodes);
        console.log("its a go go")
    } catch (error) {
        console.log(error);

    }
}