import { Link, NavLink } from "react-router-dom";
import firebase from "firebase";
import { useSelector } from "react-redux";
import { setClaims } from "../config/helpers";
import { useEffect, useState } from "react";
import InfoModal from "./InfoModal";
const NavBar = (props) => {
    useEffect(() => {
        try {
            console.log("claim setter");
            // setClaims("favour@procurementmonitor.org", { budgetHolder: true });
        } catch (error) {
            console.error("could not set claim", error);
        }
    }, []);
    let auth = useSelector(({ auth }) => auth);
    let { photoUrl, name } = auth.myuser;

    const getClaims = () => {
        let claims = [];
        auth.claims.budgetHolder && claims.push("Budget Holder");
        auth.claims.reviewer && claims.push("Reviewer");
        auth.claims.admin && claims.push("Admin");
        auth.claims.master && claims.push("Master");
        let lastclaims = claims.length > 1 ? claims.pop() : "";
        return claims.join(", ") + " and " + lastclaims;
    };
    const [showModal, setShowModal] = useState(true);

    return (
        <>
            {!localStorage.getItem("hideModal") && (
                <InfoModal
                    showModal={showModal}
                    handleClose={() => setShowModal(false)}
                />
            )}
            <section className="header-container pb-3 inner-shadow-sm px-2">
                <div className="content flex-column">
                    <a href="/" className="brand-name pt-2">
                        gwapp
                        <span className="ml-2 sub-text text-muted">
                            <small>admin</small>
                        </span>
                    </a>
                    <span
                        onClick={() => firebase.auth().signOut()}
                        className="mt-2 logout"
                    >
                        <i className="fas fa-power-off"></i> logout
                    </span>
                </div>
                <div className="d-flex align-items-center content avatar pt-4 ml-2">
                    <img
                        className="rounded-circle"
                        width="80"
                        height="80"
                        alt="avatar"
                        src={photoUrl}
                    />
                    <div className="pt-4 pl-2 header-name d-flex flex-column">
                        <h5 className="heading-5 font-weight-bold mb-0 pb-0">
                            {name}
                        </h5>
                        <div className="mt-0 pt-0">
                            {/* Signed in as.. */}
                            <p className="gw-accent-color d-inline-block mt-0 ">
                                {auth && getClaims()}
                            </p>
                        </div>
                    </div>
                    {/* <div className="content pt-4 mr-2">
                    <span
                        onClick={() => firebase.auth().signOut()}
                        className="ml-auto rounded-circle inner-outer-shadow logout-icon"
                    >
                        <i className="fas fa-power-off"></i>
                    </span>
                </div> */}
                </div>
            </section>
            <section className="mt-5 pt-4 action-section">
                <div className="d-flex">
                    <NavLink
                        to="/admin"
                        activeClassName="nav-active"
                        className="outer-shadow column mr-4"
                    >
                        <i className="fas fa-tachometer-alt gw-accent-color"></i>
                        <p>Dashboard</p>
                    </NavLink>
                    <NavLink
                        to="/project"
                        activeClassName="nav-active"
                        className="outer-shadow column mx-4"
                    >
                        <i className="fas fa-folder gw-accent-color"></i>
                        <p>Projects</p>
                    </NavLink>
                    <NavLink
                        to="/generate"
                        activeClassName="nav-active"
                        className="outer-shadow column mx-4"
                    >
                        <i className="fas fa-recycle gw-accent-color"></i>
                        <p>Generate</p>
                    </NavLink>
                    <NavLink
                        to="/analytics"
                        activeClassName="nav-active"
                        className="outer-shadow column ml-4"
                    >
                        <i className="fas fa-chart-line gw-accent-color"></i>
                        <p>Gwapplytics</p>
                    </NavLink>
                    <NavLink
                        to="/theme"
                        activeClassName="nav-active"
                        className="outer-shadow column ml-4"
                    >
                        <i className="fas fa-cog gw-accent-color"></i>
                        <p>Theme</p>
                    </NavLink>
                </div>
            </section>
        </>
    );
};

export default NavBar;
