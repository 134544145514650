import { Link, useRouteMatch } from "react-router-dom";
import { currencyCodes, pdfTable, printDocument } from "../config/helpers";
import { convertArrayToCSV } from "convert-array-to-csv";
import { useState } from "react";
import * as dayjs from "dayjs";
import * as accounting from "accounting";
import LoadingItems from "./subcomponents/LoadingItems";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import firebase from "firebase";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SearchListItem = (props) => {
    // date,
    let { time, id, project, user, type, currency, title, total } =
        props.reqItem;

    let { url } = useRouteMatch();
    let [isPrinting, setIsPrinting] = useState(false);
    let [isDownloading, setIsDownloading] = useState(false);

    let reqDate = new dayjs(Math.abs(time)).format("D MMM, YY");
    let curr = currencyCodes[currency];
    let amount = accounting.formatMoney(total, curr, 2);

    const print = async () => {
        setIsPrinting(true);
        let db = await firebase.database().ref(`requisitions/${id}`).get();
        let req = db.val();
        req.id = db.key;
        let convos = [];
        return firebase
            .database()
            .ref(`conversations/${id}`)
            .once("value", async (snapshot) => {
                snapshot.forEach((snap) => {
                    convos.push(snap.val());
                });
                let dd = await printDocument(req, convos);
                setIsPrinting(false);
                pdfMake.createPdf(dd).open();
            });
    };

    const download = async () => {
        setIsDownloading(true);
        let db = await firebase.database().ref(`requisitions/${id}`).get();
        let req = db.val();
        req.id = db.key;
        let convos = [];
        return firebase
            .database()
            .ref(`conversations/${id}`)
            .once("value", async (snapshot) => {
                snapshot.forEach((snap) => {
                    convos.push(snap.val());
                });
                let dd = await printDocument(req, convos);
                setIsDownloading(false);
                pdfMake.createPdf(dd).download();
            });
    };

    if (total === 0 || total === undefined || total === null) {
        return null;
    }

    return (
        <div className="row mt-3 py-3">
            <div className="col-lg-2 mb-4 mb-lg-0 d-flex align-items-center">
                <img
                    width="50"
                    height="50"
                    className="item-avatar mr-3 ml-2"
                    alt="user"
                    src={user?.photoUrl || ""}
                />
                <h5 className="item-name">{user?.name || ""}</h5>
            </div>
            <div className="col-lg-3 d-flex align-items-center">
                <div>
                    <p className="date gw-accent-color m-0">{reqDate}</p>
                    <p className="desc m-0 mt-1">{title}</p>
                </div>
            </div>
            <div className="col-lg-5  det-container">
                <div className="col">
                    <span>Type</span>
                    <h5 className="check-name mt-1">
                        {type === "Petty Cash" ? "Petty Cash" : "Fund Req"}
                    </h5>
                </div>
                <div className="col">
                    <span>Project</span>
                    <h5 className="check-name mt-1">{project}</h5>
                </div>
                <div className="col">
                    <span>Amount</span>
                    <h5 className="check-name mt-1">{amount}</h5>
                </div>
            </div>
            <div className="col-lg-2 d-flex align-items-center action-btn-container">
                <Link
                    to={`${url}/view/${id}`}
                    type="button"
                    className="action-btn btn btn-gw-circle outer-shadow-sm gw-success-color"
                >
                    <i className="fas fa-eye gw-succes-color"></i>
                </Link>
                <button
                    onClick={async () => await print()}
                    type="button"
                    className="action-btn btn btn-gw-circle outer-shadow-sm gw-success-color"
                >
                    {isPrinting ? (
                        <i className="fa fa-spinner spinning"></i>
                    ) : (
                        <i className="fas fa-print gw-info-color"></i>
                    )}
                </button>
                <button
                    onClick={async () => await download()}
                    type="button"
                    className="action-btn btn btn-gw-circle outer-shadow-sm gw-success-color"
                >
                    {isDownloading ? (
                        <i className="fa fa-spinner spinning"></i>
                    ) : (
                        <i className="fas fa-download gw-succes-color"></i>
                    )}
                </button>
            </div>
        </div>
    );
};

const AnalyticsSearchList = (props) => {
    let { projects, perpage } = props;
    let [currentPage, setCurrentPage] = useState(1);
    let [isGenerating, setGenerating] = useState(false);

    if (!projects) {
        return <LoadingItems />;
    }
    let pages = Math.ceil(projects.length / perpage);
    let start = (currentPage - 1) * perpage;
    let end =
        start + perpage >= projects.length
            ? projects.length - 1
            : start + perpage;
    end = end + 1;
    let rendered = projects.slice(start, end);
    const next = () => {
        let nexpage = currentPage + 1;
        if (nexpage > pages) return;
        setCurrentPage(nexpage);
    };

    const previous = () => {
        let prevpage = currentPage - 1;
        if (prevpage < 1) return;
        setCurrentPage(prevpage);
    };
    const downloadCSV = () => {
        let dataObjects = [];
        projects.forEach((project) => {
            let date = dayjs(Math.abs(project.date)).format("YYYY/MM/DD");
            let reviewDate = project.reviewDate
                ? dayjs(Math.abs(project.reviewDate)).format("YYYY/MM/DD")
                : "-";
            let approvedDate = project.approvedDate
                ? dayjs(Math.abs(project.approvedDate)).format("YYYY/MM/DD")
                : "-";
            let holderCheckDate = project.holderCheckDate
                ? dayjs(Math.abs(project.holderCheckDate)).format("YYYY/MM/DD")
                : "-";
            let checkDate = project.checkDate
                ? dayjs(Math.abs(project.checkDate)).format("YYYY/MM/DD")
                : "-";
            let time = dayjs(Math.abs(project.time)).format("YYYY/MM/DD");
            let title = project.title.replace(/#/g, "N");
            // let items = project.items.replace(/#/g, "N");
            let strItems = project.items.toString();
            let items = strItems.replace(/#/g, "N");

            let id = project.id.replace(/-/g, "");
            let newdata = {
                ...project,
                title,
                date,
                time,
                id,
                items,
                user: project.user.name,
                reviewDate,
                approvedDate,
                holderCheckDate,
                checkDate,
                accountName: project?.accountName,
                accountNumber: project?.accountNumber,
                bankName: project?.bankName,
            };
            dataObjects.push(newdata);
        });
        // console.log(dataObjects.length);
        // console.log(dataObjects[300]);
        // console.log(projects[300]);
        // return;
        let csv = convertArrayToCSV(dataObjects);
        let filename = `gwapp-export.csv`;
        if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
        }
        let data = encodeURI(csv);

        let link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", filename);
        link.click();
    };

    const printPDF = async () => {
        console.log("printing");
        setGenerating(true);
        let doc = await pdfTable(projects);
        console.log(doc);
        pdfMake.createPdf(doc).download();
        setGenerating(false);
    };
    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="">
                    <button
                        onClick={downloadCSV}
                        type="button"
                        className={`btn btn-small btn-gw-primary-outline mx-2 `}
                    >
                        Download CSV
                    </button>
                    <button
                        onClick={() => {
                            printPDF();
                        }}
                        type="button"
                        className={`btn btn-small btn-gw-success-outline mx-2 ${
                            isGenerating && "loading disabled"
                        }`}
                    >
                        {isGenerating ? (
                            <i className="fa fa-spinner spinning"></i>
                        ) : (
                            "Download PDF"
                        )}
                    </button>
                </div>
            </div>
            <div className="my-3">
                {rendered.map((project, i) => (
                    <SearchListItem key={i} reqItem={project} index={i} />
                ))}
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-center align-items-center">
                    <p>
                        Showing {start + 1} to {end} of {projects.length}{" "}
                        entries
                    </p>
                </div>
                <div className="d-flex align-items-center">
                    <button
                        type="button"
                        onClick={() => previous()}
                        className="btn gw-btn-primary-outline"
                    >
                        Prev
                    </button>
                    <span className="d-inline-block">
                        {currentPage} of {pages}
                    </span>
                    <button
                        type="button"
                        onClick={next}
                        className="btn gw-btn-primary"
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default AnalyticsSearchList;
