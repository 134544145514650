

import { BrowserRouter as Router} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {useEffect} from 'react';
import {fetchUser, fetchStats} from  './actions/authActions';
import './config/firebaseconfig';
import { defaultDark, defaultLight, royalDark, darKLemon } from  "./themes";
import Login from './components/Login';
import LoadingScreen from './components/LoadingScreen';
import Navbar from './components/NavBar';
import MobileHeader from './components/MobileNavHeader';
import MobileNav from './components/MobileNav';
import MainRoute from './routes';
import './css/base.css';
import './css/admin.css';

function App() {
  const auth = useSelector(({auth})=>auth);
  console.log({auth})
  var dispatch = useDispatch();
  let themeMap = {
    "default": defaultLight,
    "royal-dark": royalDark,
    "dark": defaultDark,
    "dark-lemon":darKLemon
  }
  const theme = useSelector(({theme})=> theme);
  const ThemeComponent =  themeMap[theme];
  useEffect(()=>{
    fetchUser(dispatch);
  }, []);

  useEffect(()=>{
    fetchStats(dispatch)
  }, []);

  if(auth){
    return (
      <Router>
        <MobileHeader />
        <div className = "container my-5">
          <Navbar />
          <MainRoute />
        </div>
        <MobileNav />
        <ThemeComponent/>
      </Router>
     );
  }
  if(auth === false){
    return(
    <>
    <Login />
    <ThemeComponent/>
    </>
    )
  }

  return (
    <>
   
    <LoadingScreen />
    </>
  )
  
}

export default App;
