import {Formik, FieldArray, useFormikContext} from 'formik'
import  {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import LoadingScreen from '../components/LoadingScreen';
import { fetchAccounts } from '../actions/dashboardActions'
import firebase from 'firebase'
import {Link} from 'react-router-dom';

const AccountItem = props =>{
    let {index, handleChange, account, id} = props;
    let [mode, setMode ] = useState('view');
    let [isSaving, setIsSaving] = useState(false);
    let {values, setFieldValue } = useFormikContext();
    const save = async ()=>{
        let account =  values.accounts[index];
        console.log(account);
        setIsSaving(true);
        await firebase.database().ref(`accounts/${account.id}`).set(account);
        setIsSaving(false);
        setMode('view');
        

    }
    const deleteAccount = async ()=>{
        let account = values.accounts.splice(index, 1);
        setIsSaving(true);
        setFieldValue('accounts', values.accounts);
        await firebase.database().ref(`accounts/${account.id}`).remove();
    }
    return (
        <div className='row outer-shadow-sm my-2'>
            <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
                <label className="d-lg-none">Account Number</label>
                <input name = {`accounts.${index}.accountNumber`} onChange = {handleChange} type="text" value={account.accountNumber} className={`item-input  ${mode === 'view' ? "disabled":  'inner-shadow'}`} />
            </div>
            <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
                <label className="d-lg-none">Account Name</label>
                <input name = {`accounts.${index}.accountName`} onChange = {handleChange} type="text" value={account.accountName} className={`item-input  ${mode === 'view' ? "disabled":  'inner-shadow'}`} />
            </div>
            <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
                <label className="d-lg-none">Bank Name</label>
                <input name = {`accounts.${index}.bankName`} onChange = {handleChange} type="text" value={account.bankName} className={`item-input  ${mode === 'view' ? "disabled":  'inner-shadow'}`}/>
            </div>
            <div className="col-lg-3 d-lg-flex justify-content-center py-3">
                {
                    mode == "view" ? 
                        <button type = "button" onClick = {()=>setMode('edit')} className="btn gw-primary-color btn-md">
                            <i className="fas fa-pen"></i>
                        </button> :
                    <button type = "button" onClick = {()=>save()} className={`btn btn-gw-info btn-md ${isSaving && 'disabled loading'}`}>
                            <i className="fas fa-save"></i>
                        </button>

                }
                
                <button type = "button" className="btn gw-danger-color btn-md" onClick = {()=>deleteAccount()}>
                    <i className="fas fa-trash"></i>
                </button>
            </div>
        </div>
    )
}

const Accounts = props =>{
    let accounts = useSelector(({accounts})=>accounts);
    let dispatch = useDispatch();
    let [searchTerm, setSearchTerm] = useState('');
    useEffect(()=>{
        try {
            fetchAccounts(dispatch);
        } catch (error) {
            console.log(error)
        }
    }, [])
    const newAccount = ()=>{
        return {
            
                accountName: '',
                bankName: '',
                accountNumber: '',
                id: firebase.database().ref('projects').push().key
            
        }
    }

    return(
        
        <section className="my-5 py-5 px-2 px-lg-0">
            <div className = "d-flex justify-content-end my-5">
                    <Link to='/project' className="align-self-end gw-info-color">
                        <i className="fas fa-arrow-left gw-info-color"></i> Go Back
                    </Link>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="heading-5">Accounts</h5>
                <input type="text" onChange = {(e)=>setSearchTerm(e.target.value)} value = {searchTerm} className="inner-shadow-sm search-sm" placeholder="Search Account Number" />
            </div>
            {
                accounts && accounts.length >0 ?
                    <div className="account-list list my-2">
                        <Formik
                            initialValues={
                                {
                                    accounts
                                }
                            }
                        >
                            {
                                ({ values, handleChange, setFieldValue }) => (
                                    <>
                                    {values.accounts.map((account, i)=>(
                                        account.accountNumber.indexOf(searchTerm) > -1 && <AccountItem account = {account} index = {i} handleChange = {handleChange} id = {account.id} />
                                    ))}
                                    <div className = "d-flex justify-content-end">
                                            <button onClick = {()=>{values.accounts.push(newAccount()); setFieldValue('accounts',values.accounts)}} type = "button" className = "btn gw-info-color btn-md">Add Account</button>
                                    </div>
                                    </>
                                    
                        
                    )
                            }
                        </Formik>
                    </div>:
                    <LoadingScreen />

            }
        </section>
    )
}

export default Accounts