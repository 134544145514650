
import HomeRoute from './HomeRoute';
import ProjectsRoute from './ProjectsRoute';
import AnalyticsRoute from './AnalyticsRoute';
import { Switch, Route, Redirect } from 'react-router-dom';
import ThemeSelector from '../components/ThemeSelector';




const MainRoute = props => {

    return (
        <Switch>
            <Route exact path='/'>
                <Redirect to='/admin' />
            </Route>
            <Route path='/project'>
                <ProjectsRoute />
            </Route>
            <Route path='/admin'>
                <HomeRoute />
            </Route>
            <Route path="/analytics">
                <AnalyticsRoute />
            </Route>
            <Route path="/theme">
                <h3 className="mt-5 mb-3">Select Theme</h3>
                <ThemeSelector />
            </Route>
        </Switch>);
}


export default MainRoute;