import { Formik, Form } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  fetchProjectsChargeTo,
  analyticsSearch,
  fetchUserList,
} from "../../actions/dashboardActions";

const AnalyticsSearchForm = (props) => {
  let { projects, users, filterProject } = props;
  
  let dispatch = useDispatch();

  useEffect(() => {
    if (!projects) {
      fetchProjectsChargeTo(dispatch);
    }
    if (!users) {
      fetchUserList(dispatch);
    }
  }, []);
  useEffect(() => {
    if (!projects) {
      fetchProjectsChargeTo(dispatch);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        project: "",
        user: "",
        startDate: "",
        endDate: "",
      }}
      validate={(values) => {
        let errors = {};
        if (values.endDate && !values.startDate) {
          errors.startDate =
            "You must select a start date for the corresponding end date";
        }
        if (values.endDate && values.startDate) {
          let starttime = new Date(values.startDate).getTime();
          let endTime = new Date(values.endDate).getTime();
          if (starttime > endTime) {
            errors.startDate = "End Date cannnot be before the start date";
          }
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log({ values });
        console.log({ searchvalues: values });
        setSubmitting(true);
        let data = await analyticsSearch(values);
        filterProject(data.projects);
      }}
    >
      {({ values, handleChange, errors, touched, isSubmitting, resetForm }) => (
        <Form>
          <section className="my-5 pt-3 row">
            <div className="col filter">
              <h6 className="mt-4 heading-6">Search </h6>
              <div className="mt-4">
                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="user">User</label>
                    <select
                      id="user"
                      value={values.user}
                      name="user"
                      onChange={handleChange}
                      className={`browser-default custom-select outer-shadow ${
                        !users && "loading disabled"
                      } `}
                    >
                      <option value="">Select user</option>
                      {users &&
                        users.length > 0 &&
                        users.map((user, i) => (
                          <option key={i} value={user.id}>{user.name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col">
                    <label htmlFor="project">Projects</label>
                    <select
                      id="project"
                      value={values.project}
                      name="project"
                      onChange={handleChange}
                      className={`"browser-default custom-select outer-shadow ${
                        !projects && "loading disabled"
                      }`}
                    >
                      <option value="all">All</option>
                      {projects &&
                        projects.length > 0 &&
                        projects.map((project) => (
                          <option value={project.projectName}>
                            {project.projectName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <button
                  className={`btn btn-gw-primary btn-md ${
                    isSubmitting && "disabled loading"
                  }`}
                >
                  <i className="fas fa-filter mr-2"></i>
                  Filter
                </button>

                <button
                  onClick={() => resetForm()}
                  className={`btn btn-gw-primary-outline btn-md ${
                    isSubmitting && "disabled loading"
                  }`}
                >
                  <i className="fas fa-times mr-2"></i>
                  Clear
                </button>
              </div>
            </div>
            <div className="col d-none d-lg-block">
              <h6 className="mt-4 heading-6">Time Period</h6>
              <div className="mt-4">
                <div className="form-row mb-4">
                  <div className="col pick-date">
                    <label htmlFor="startDate"> Start Date</label>
                    <input
                      id="startDate"
                      value={values.startDate}
                      name="startDate"
                      placeholder="Start date"
                      type="date"
                      onChange={handleChange}
                      className={`px-3 form-control datepicker outer-shadow ${
                        touched.startDate && errors.startDate && "is-invalid"
                      }`}
                    />
                    <p className="invalid-feedback">
                      {touched.startDate && errors.startDate}
                    </p>
                  </div>
                  <div className="col pick-date">
                    <label htmlFor="endDate">End Date</label>
                    <input
                      id="endDate"
                      placeholder="End date"
                      type="date"
                      values={values.endDate}
                      name="endDate"
                      onChange={handleChange}
                      className={`px-3 form-control datepicker outer-shadow ${
                        touched.startDate && errors.startDate && "is-invalid"
                      }`}
                    />
                    <p className="invalid-feedback">
                      {touched.endDate && errors.endDate}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default AnalyticsSearchForm;
