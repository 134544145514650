import {useRouteMatch, Switch, Route} from 'react-router-dom';
import ProjectPageLinks from '../components/ProjectPageLinks';
import Accounts from '../components/Accounts';
import ProjectList from '../components/ProjectList';
import BudgetCodes from '../components/BudgetCodes'

const ProjectsRoute = (props)=>{
    let {path} = useRouteMatch()
    return(
        <>
            <Route exact  path = {`${path}/accounts`} >
                <Accounts />
            </Route>
            <Route exact  path = {path}>
                <ProjectPageLinks />
            </Route>
            
            <Route  path = {`${path}/projects`} >
                <ProjectList />
            </Route>
            <Route  path = {`${path}/budget`} > 
                <BudgetCodes />
            </Route>
            
        </>
        
    )
}

export default ProjectsRoute;