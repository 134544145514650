
import {Link, useRouteMatch} from 'react-router-dom';


const ProjectPageLinks = props =>{
    let { url } = useRouteMatch(); 

    return(
        <section className = "my-5 py-5">
                    <div className = "row">
                        <div className = "col-lg-4 mb-4 mb-lg-0">
                            <Link to = {`${url}/accounts`} className = "project-card outer-shadow-sm px-5 py-4">
                                <div className = "project-header">
                                    <h5>Account</h5>
                                    <img className = "account-image" src = './refund.png' />
                                </div>
                                <p>
                                    Add , Edit and Mangage the different bank account details of the organisation 
                                </p>
                                <button className="btn btn-gw-primary btn-md">
                                    View
                                    <i className="fas fa-angle-double-right ml-2"></i>
                                
                                </button>
                            </Link>
                        </div>
                        <div className = "col-lg-4 mb-4 mb-lg-0">
                            <Link to = {`${url}/projects`} className = "project-card outer-shadow-sm px-5 py-4">
                                <div className = "project-header">
                                    <h5>Projects</h5>
                                    <img className = "account-image" src = './portfolio.png' />
                                </div>
                                <p>
                                    Manage all projects and their associated accounts and funders 
                                </p>
                                <button className="btn btn-gw-primary btn-md">
                                    View
                                    <i className="fas fa-angle-double-right ml-2"></i>
                                
                                </button>
                            </Link>
                        </div>
                        <div className = "col-lg-4 mb-4 mb-lg-0">
                            <Link to = {`${url}/budget`} className = "project-card outer-shadow-sm px-5 py-4">
                                <div className = "project-header">
                                    <h5>Budget/Codes</h5>
                                    <img className = "account-image" src = './budget.png' />
                                </div>
                                <p>
                                    Add, edit remove and manage budgets and their related codes for each project 
                                </p>
                                <button className="btn btn-gw-primary btn-md">
                                    View
                                    <i className="fas fa-angle-double-right ml-2"></i>
                                
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>
    )
}


export default ProjectPageLinks;