import LoadingScreen from '../components/LoadingScreen';
import {useParams, Link} from 'react-router-dom';
import {currencyCodes} from '../config/helpers'
import {useEffect, useState} from 'react';
import firebase from 'firebase';
import * as accounting from 'accounting';
import * as dayjs from 'dayjs';


const Checker = props =>{
    return(
        <div className = "col d-flex flex-column align-items-center">
            <label>{props.label}</label>
            <p>{props.name}</p>
            <div>
                <img src = {props.url} width= "50" height = "30"/>
            </div>
            <p class = "checker-date">{props.date}</p>
        </div>
    )
}

const AnalysisView = props =>{
    let {id} = useParams();
    let [req, setReq] = useState(null);

    useEffect(async()=>{
        let myreq = await firebase.database().ref(`requisitions/${id}`).get();
        setReq(myreq.val());
    }, [])
    let date = req && dayjs(Math.abs(req.date)).format('D MMM, YYYY');

    if(req === null){
        return <LoadingScreen/>
    }
    return(
        <>
            <div className="outer-shadow mt-5 pt-5 req-cont px-5">
                <div className="d-flex justify-content-end">
                    <Link to='/analytics' className="align-self-end btn-gw-primary-outline">
                        <i className="fa fa-times gw-info-color"></i>
                    </Link>
                </div>
                <div className="mt-5 px-lg-5">
                    <section className="d-flex justify-content-between">
                        <div>
                            <h6 className="heading-5">Name</h6>
                            <p>{req.user.name}</p>
                        </div>
                        <div>
                            <h6 className="heading-5">Date</h6>
                            <p>{date}</p>
                        </div>
                        <div>
                            <h6 className="heading-5">Type</h6>
                            <p>{req.type || 'Fund Req'}</p>
                        </div>
                    </section>
                    <section className="mt-4 pt-4">
                        <h6 className="heading-5">Description</h6>
                        <p>{req.title}</p>
                    </section>
                    <section className="d-flex justify-content-between mt-4 pt-4 beneficiary">
                        <div>
                            <h6 className="heading-5">Name of Beneficiary</h6>
                            <p>{req.accountName}</p>
                        </div>
                        <div>
                            <h6 className="heading-5">Account Number</h6>
                            <p>{req.accountNumber}</p>
                        </div>
                        <div>
                            <h6 className="heading-5">Bank</h6>
                            <p>{req.bankName}</p>
                        </div>
                    </section>
                    <section className="mt-4 pt-4">
                        <h6 className="heading-6 mb-3">Invoices</h6>
                        <div className="d-flex invoices">
                            {
                                req.invoices && req.invoices.map((invoice) => (
                                    <div className="outer-shadow column-sm mr-4">
                                        <p>{invoice.name}</p>
                                        <a className="gw-info-color" href={invoice.url} target="_blank">
                                            <i className="fas fa-eye gw-info-color"></i>
                                        </a>
                                    </div>
                                ))
                            }

                        </div>
                    </section>
                    {
                        req.receipts && (
                            <section className="mt-4 pt-4">
                                <h6 className="heading-6 mb-3">Receipts</h6>
                                <div className="d-flex invoices">
                                    {
                                        req.receipts && req.receipts.map((invoice) => (
                                            <div className="outer-shadow column-sm mr-4">
                                                <p>{invoice.name}</p>
                                                <a className="gw-info-color" href={invoice.url} target="_blank">
                                                    <i className="fas fa-eye gw-info-color"></i>
                                                </a>
                                            </div>
                                        ))
                                    }

                                </div>
                            </section>

                        )
                    }
                    <section className="mt-4 pt-4 row">
                        <div className="col-lg-12">
                            <h6 className="heading-6 mb-3">Item List</h6>
                            {
                                req.items.map((item) => (
                                    <div className="outer-shadow row mt-3 p-3">
                                        <div className="flex-grow-1 col-lg-5 col-12">
                                            <p className="m-0">{item.title}</p>
                                        </div>
                                        <div className="col-lg-2 col-12">
                                            <h5 className="m-0 heading-5">{accounting.formatNumber(item.amount, 2)}</h5>
                                        </div>
                                        <div className="col-lg-5 col-2 flex-grow-1">
                                            <p className="m-0">{item.code || ''}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                    <div className="mt-4 pt-4 row figures px-1">
                        <div className="col-6">
                            <h6 className="heading-6">Total</h6>
                            <p className="m-0">{accounting.formatMoney(req.total, currencyCodes[req.currency])}</p>
                        </div>
                        <div className="col-6">
                            <h6 className="heading-6">Amount in Words</h6>
                            <p className="m-0">{req.amountInWords}</p>
                        </div>
                    </div>
                    {
                        req.type === "Fund Req" && (
                            <section className="mt-4 pt-4 source">
                                            <div className="d-flex justify-content-between mt-4">
                                                <div>
                                                    <h6 className="heading-6">Projec</h6>
                                                    <p>{req.projectName}</p>
                                                </div>
                                                <div>
                                                    <h6 className="heading-6">Source Account Number</h6>
                                                    <p>{req.sourceAccountNumber}</p>
                                                </div>
                                                <div>
                                                    <h6 className="heading-6">Bank</h6>
                                                    <p>{req.sourceBankName}</p>
                                                </div>
                                            </div>
                            </section>
                        )
                    }

                    <section className="mt-4 pt-4">
                        <div className="row">
                            {
                                req.user && <Checker label = "Raised By" name = {req.user.name} url = {req.user.signatureUrl} date = {dayjs(Math.abs(req.date)).format('D MMM, YYYY')}/>
                            }
                            {
                                req.holderCheck && <Checker label = "Budget Holder" name = {req.holderCheck.name} url = {req.holderCheck.signatureUrl} date = {dayjs(Math.abs(req.hoderCheckDate)).format('D MMM, YYYY')}/>
                            }
                            {
                                req.checkedBy && <Checker label = "Checked By" name = {req.checkedBy.name} url = {req.checkedBy.signatureUrl} date = {dayjs(Math.abs(req.checkDate)).format('D MMM, YYYY')} />
                            }
                            {
                                req.reviewedBy && <Checker label = "Reviewed By" name = {req.reviewedBy.name} url = {req.reviewedBy.signatureUrl} date = {dayjs(Math.abs(req.reviewDate)).format('D MMM, YYYY')} />
                            }
                            {
                                req.approvedBy && <Checker label = "Approved By" name = {req.approvedBy.name} url = {req.approvedBy.signatureUrl} date = {dayjs(Math.abs(req.approvedDate)).format('D MMM, YYYY')} />
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    )

}

export default AnalysisView