const Searcher = (props) => {
    let {
        filter,
        period,
        startDate,
        endDate,
        setFilter,
        setPeriod,
        setStartDate,
        setEndDate,
        periodFilter,
        timelineFilter,
        isLoading,
    } = props;
    return (
        <section className="mt-3 p-3 row border shadow">
            <div className="col filter">
                <h6 className="mt-4 heading-6">Filter Requisition</h6>
                <div className="mt-4">
                    <div className="form-row mb-4">
                        <div className="col">
                            <label className="item-label">
                                Show requisitions
                            </label>
                            <select
                                value={filter}
                                name="filter"
                                onChange={setFilter}
                                className="browser-default custom-select outer-shadow"
                            >
                                <option value="myattention">
                                    For My Attention
                                </option>
                                <option value="all">All</option>
                            </select>
                        </div>
                        <div className="col">
                            <label className="item-label">Period</label>
                            <select
                                value={period}
                                name="period"
                                onChange={setPeriod}
                                className="browser-default custom-select outer-shadow"
                            >
                                <option value="all">All</option>
                                <option value="week">This Week</option>
                                <option value="month">This Month</option>
                            </select>
                        </div>
                    </div>
                    <button
                        onClick={() => periodFilter()}
                        className={`btn btn-gw-primary btn-md ${
                            isLoading && "disabled loading"
                        }`}
                    >
                        <i className="fas fa-filter mr-2"></i>
                        Filter
                    </button>
                </div>
            </div>
            <div className="col d-none d-lg-block">
                <h6 className="mt-4 heading-6">Advanced Filter</h6>
                <div className="mt-4">
                    <div className="form-row mb-4">
                        <div className="col pick-date">
                            <label className="item-label">Start Date</label>
                            <input
                                value={startDate}
                                name="startDate"
                                placeholder="Start date"
                                type="date"
                                onChange={setStartDate}
                                className="px-3 form-control datepicker outer-shadow"
                            />
                        </div>
                        <div className="col pick-date">
                            <label className="item-label">End Date</label>
                            <input
                                placeholder="End date"
                                type="date"
                                values={endDate}
                                name="endDate"
                                onChange={setEndDate}
                                className="px-3 form-control datepicker outer-shadow"
                            />
                        </div>
                    </div>
                    <button
                        onClick={() => timelineFilter()}
                        type="button"
                        className={`btn btn-gw-primary btn-md ${
                            isLoading && "disabled loading"
                        }`}
                    >
                        <i className="fas fa-filter mr-2"></i>
                        Filter
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Searcher;
