import React, { useState, useEffect } from "react";

const InfoModal = ({ showModal, handleClose }) => {
    const [hideModal, setHideModal] = useState(false);

    useEffect(() => {
        const hideModalFlag = localStorage.getItem("hideModal");
        if (hideModalFlag === "true") {
            setHideModal(true);
        }
    }, []);

    const handleCheckboxChange = (e) => {
        setHideModal(e.target.checked);
        localStorage.setItem("hideModal", e.target.checked);
    };

    return (
        <div
            className={`info-modal modal fade ${showModal ? "show" : ""}`}
            style={{ display: showModal ? "block" : "none" }}
            tabIndex="-1"
            role="dialog"
        >
            <div className="modal-dialog" role="document">
                <div
                    className="modal-content"
                    style={{
                        borderRadius: "20px",
                        backgroundColor: "#006c37",
                        color: "#fff",
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title"
                            // style={{ color: "darkgreen" }}
                        >
                            You are now accessing Gwapp in restricted mode
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ color: "#f7f7f7" }}>
                        <h6>
                            <strong>
                                To mark requisitions as checked, please head over to{" "}
                                <code
                                    style={{
                                        padding: 5,
                                        backgroundColor: "white",
                                        color: "#1a1a1a",
                                    }}
                                >
                                    gwapp.ppdc.org
                                </code>{" "}
                                in a new tab and contine there!
                            </strong>
                        </h6>
                        <p style={{ marginTop: "30px" }}>
                            <strong>NOTE:{" "}</strong>
                            If you don't see the latest requests on the new
                            Gwapp, check again after a while! Data is being
                            migrated.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-dark"
                            data-dismiss="modal"
                            onClick={handleClose}
                            id="close-btn"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoModal;