
import {useSelector, useDispatch} from 'react-redux'
import {useEffect} from 'react';
import { fetchChateredCodes } from '../../actions/dashboardActions'
import {useFormikContext} from 'formik'


const ChartsAccount = props =>{
    let codes = useSelector(({chartedCodes})=>chartedCodes);
    let dispatch = useDispatch();
    let {setFieldValue, values} = useFormikContext();
    console.log(values);
    useEffect(()=>{
        fetchChateredCodes(dispatch);
    }, []);

    const handleProjectChange = (e)=>{
      
        setFieldValue('codes', e.target.value );
    
    }
    
    if(!codes){
        return(
            <select className="form-control custom-select outer-shadow loading disabled">
                                    <option value="">Please Wait...</option>
            </select>
        )
    }
    else{
        return(
            <select name = "codes" value = {values.codes} className="form-control custom-select outer-shadow" onChange = {handleProjectChange}>
                <option value = "">Code</option>
                {
                    codes.map((code, i)=>(
                        <option value = {code}>{code}</option>
                    ))
                }
            </select>
        )
    }  
}


export default ChartsAccount;