import {Formik, FieldArray, useFormikContext} from 'formik'
import  {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import LoadingScreen from '../components/LoadingScreen';
import { fetchAccounts, fetchProjectsChargeTo } from '../actions/dashboardActions'
import firebase from 'firebase';
import {Link} from 'react-router-dom';

const ProjectItem = props =>{
    let {index, handleChange, project, accounts, id} = props;
    
    let [mode, setMode ] = useState('view');
    let [isSaving, setIsSaving] = useState(false);
    let {values, setFieldValue} = useFormikContext();
    let selectValue = accounts.findIndex((account)=>account.accountNumber == project.account.accountNumber);
    selectValue = selectValue < 0 ? 0 : selectValue;
    console.log({selectValue});
    const save = async ()=>{
        let project =  values.projects[index];
        console.log(project);
        setIsSaving(true);
        await firebase.database().ref(`projects/${project.id}`).set(project);
        setIsSaving(false);
        setMode('view');
        

    }
    const onSelectChange = (e)=>{
        let theaccount = accounts.find((account)=> account.accountNumber == e.target.value);
        if (theaccount) setFieldValue(`projects.${index}.account`, theaccount);

    }

    const deletProject = async ()=>{
        let project = values.projects.splice(index, 1);
        setIsSaving(true);
        setFieldValue('projects', values.projects);
        await firebase.database().ref(`projects/${project.id}`).remove();
    }
    return (
        <div className='row outer-shadow-sm my-2'>
            <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
                <label className="d-lg-none">Project Name</label>
                <input name = {`projects.${index}.projectName`} onChange = {handleChange} type="text" value={project.projectName} className={`item-input  ${mode === 'view' ? "disabled":  'inner-shadow'}`} />
            </div>
            <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
                <label className="d-lg-none">Funder</label>
                <input name = {`projects.${index}.funder`} onChange = {handleChange} type="text" value={project.funder} className={`item-input  ${mode === 'view' ? "disabled":  'inner-shadow'}`} />
            </div>
            <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
                <label className="d-lg-none">Bank Name</label>
                <select onChange = { onSelectChange } value = {accounts[selectValue || 0].accountNumber} className={`browser-default custom-select  ${mode === 'view' ? "disabled":  'inner-shadow-sm'}`}>
                    {
                        accounts.map((account)=>(
                            <option value = {account.accountNumber}> {account.bankName}</option>
                        ))
                    }
                </select>
            </div>
            <div className="col-lg-3 d-lg-flex justify-content-center py-3">
                {
                    mode == "view" ? 
                        <button type = "button" onClick = {()=>setMode('edit')} className="btn gw-primary-color btn-md">
                            <i className="fas fa-pen"></i>
                        </button> :
                    <button type = "button" onClick = {()=>save()} className={`btn btn-gw-info btn-md ${isSaving && 'disabled loading'}`}>
                            <i className="fas fa-save"></i>
                        </button>

                }
                
                <button type = "button" onClick = {()=> deletProject()} className="btn gw-danger-color btn-md">
                    <i className="fas fa-trash"></i>
                </button>
            </div>
        </div>
    )
}

const ProjectList = props =>{
    let {accounts, projectsCharge} = useSelector(({accounts, projectsCharge})=>{return {accounts, projectsCharge}});
    let dispatch = useDispatch();
    let [searchTerm, setSearchTerm] = useState('');
    useEffect(()=>{
        if(!accounts){
            try {
                fetchAccounts(dispatch);
            } catch (error) {
                console.log(error)
            }
        }
        
    }, [])
    useEffect(()=>{
        if(!projectsCharge){
            try {
                fetchProjectsChargeTo(dispatch);
            } catch (error) {
                
            }
        }
    }, [])
    const newProject = ()=>{
        return {
            funder: '',
            projectName: '',
            account: {
                accountName: '',
                bankName: '',
                accountNumber: ''
            },
            id: firebase.database().ref('projects').push().key
        }
    }

    return(
        
        <section className="my-5 py-5 px-2 px-lg-0">
            <div className = "d-flex justify-content-end my-5">
                    <Link to='/project' className="align-self-end gw-info-color">
                        <i className="fas fa-arrow-left gw-info-color"></i> Go Back
                    </Link>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="heading-5">Projects</h5>
                <input type="text" onChange = {(e)=>setSearchTerm(e.target.value)} value = {searchTerm} className="inner-shadow-sm search-sm" placeholder="Search Project" />
            </div>
            {
                accounts && accounts.length > 0 && projectsCharge && projectsCharge.length > 0 ?
                    <div className="account-list list my-2">
                        <Formik
                            initialValues={
                                {
                                    projects: projectsCharge
                                }
                            }
                        >
                            {
                                ({ values, handleChange, setFieldValue }) => (
                                    <>
                                    {
                                        values.projects.map((project, i)=>(
                                            project.projectName.toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 && <ProjectItem accounts = {accounts} project = {project} index = {i} handleChange = {handleChange} id = {project.id} />
                                        ))
                                    }
                                    <div className = "d-flex justify-content-end">
                                            <button onClick = {()=>{values.projects.push(newProject()); setFieldValue('projects',values.projects)}} type = "button" className = "btn gw-btn-primary btn-md">Add Project</button>
                                    </div>
                                    </>
                                    
                        
                    )
                            }
                        </Formik>
                    </div>:
                    <LoadingScreen />

            }
        </section>
    )
}

export default ProjectList