export const AUTH_USER = 'auth_user';
export const FETCH_REQUISIITONS = 'fetch_requisition';
export const CHARTS_CODES = 'charts_codes';
export const PROJECT_CHARGE = 'project_charge';
export const FETCH_ACCOUNTS = 'fetch_accounts';
export const BUDGET_CODES = 'budget_codes';
export const USER_LIST = 'user_list';
export const THEME_SELECT = 'theme_select';
export const SYSTEM_STATS = 'system_stats';

