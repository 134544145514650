import {createStore, applyMiddleware, combineReducers} from 'redux';
import auth, {theme} from './authReducer';
import { projects, projectsCharge, chartedCodes, accounts, budgets, users, systemStats } from "./dashboardReducers";
import reduxThunk from 'redux-thunk';

var reducers = combineReducers({
    auth,
    projects,
    projectsCharge,
    chartedCodes,
    accounts,
    budgets,
    users,
    systemStats,
    theme
})


const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

export default store;