import MultiSelect from 'react-multi-select-component';
import {useSelector, useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {fetchBudgets} from '../../actions/dashboardActions';
import {useFormikContext} from 'formik'
import '../../css/multiselect.css';


const MultiSelectComponent = (props)=>{
    let {values} = props;
    let budgets = useSelector(({budgets})=> budgets);
    let dispatch = useDispatch();
    let options = null;
    let {setFieldValue} = useFormikContext()
    useEffect(()=>{
        if(!budgets){
            fetchBudgets(dispatch);
        }
    }, []);
    if(budgets && budgets.length > 0){
         options = budgets.map((value, index)=>{
            return { value : value.code, label: `${value.code}- ${value.description}`}
        })
    }

    return (
        !options ?
            <div className="form-control outer-shadow-sm loading">

            </div> :
            <section className="mt-4 pt-4 codes">
                <h6 className="heading-6">Budget line Item</h6>
                <MultiSelect options = {options} className="" value={values.budgetLineItems} onChange={(selected) => { console.log(selected); setFieldValue('budgetLineItems', selected) }} />
            </section>
    )


}


export default MultiSelectComponent