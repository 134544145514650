import firebase from 'firebase';
import { AUTH_USER, THEME_SELECT, SYSTEM_STATS } from './types';
const tempuser = null;


export const fetchUser = (dispatch)=>{

    firebase.auth().onAuthStateChanged( async user=>{
        
        if(user){
            let userId = tempuser || user.uid;
            console.log(user);
            try {
                let IdToken = await firebase.auth().currentUser.getIdTokenResult();
                let myuser = await firebase.database().ref(`users/${userId}/profile`).get();
                console.log(myuser.val());
                user.myuser = myuser.val();
                user.claims = IdToken.claims;
                console.log(console.log(IdToken.claims))
                dispatch({type: AUTH_USER, payload: user})
            } catch (error) {
                
            }
           
        }
        else{
            dispatch({type: AUTH_USER, payload: null})
        }
    })

}

export const fetchStats = (dispatch)=>{
    firebase.database().ref('systemStats').on('value', (snapshot)=>{
        let systemStats = snapshot.val();
        console.log({systemStats});
        dispatch({type: SYSTEM_STATS, payload: systemStats})
    })
}

export const signIn = async (dispatch) =>{
    var provider = new firebase.auth.GoogleAuthProvider();
    try {
        let result = await  firebase.auth().signInWithPopup(provider)
        
        return result;

    } catch (e) {
        console.log(e)
        return {err: e, errorMessage: e.errorMessage}
    }

}


export const fetchTheme = () =>{
    let userSelectedTheme = window.localStorage.getItem('gwapp-theme');
    console.log({userSelectedTheme})
    const hasSelectedTheme = typeof userSelectedTheme === 'boolean';
    return userSelectedTheme ? userSelectedTheme: 'default';
}

export const setTheme = (theme, dispatch)=>{
    window.localStorage.setItem('gwapp-theme', theme);
    dispatch({type: THEME_SELECT, payload: theme});
}

