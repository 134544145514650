import { Link, NavLink } from "react-router-dom";
import firebase from 'firebase'
import {useSelector} from 'react-redux';
import { setClaims } from '../config/helpers';
import {useEffect} from 'react'


const MobileNavHeader = props =>{
    let auth = useSelector(({auth})=>auth);
    let {photoUrl, displayName} = auth.myuser;
    
    const getClaims = ()=>{
        console.log(auth);
        let claims = [];
        auth.claims.budgetHolder && claims.push('Budget Holder');
        auth.claims.reviewer && claims.push('Reviewer');
        auth.claims.admin && claims.push('Admin');
        auth.claims.master && claims.push('Master');
        let lastclaims = claims.length > 1 ? claims.pop() : '';
        return  claims.join(', ') + " and " + lastclaims
    }

    return (
        <div>
                <div className="col-lg-9 pt-lg-1 d-lg-none">
                    <section className="d-flex px-2">
                        <a href="#" className="align-self-center brand-name">gwapp</a>
                        <span onClick ={()=>firebase.auth().signOut()} className="ml-auto rounded-circle inner-outer-shadow logout-icon-sm">
                            <i className="fas fa-power-off"></i>
                        </span>
                    </section>
                    <section className="inner-shadow mt-5 px-2 py-4 mobile-header">
                        <div className="d-flex justify-content-around align-items center">
                            <img className="rounded-circle" width = "60" height = "60" alt="avatar" src={photoUrl}/>
                            <div className="d-flex flex-column align-self-center">
                                <h5 className="heading-5 font-weight-bold">{displayName}</h5>
                                <p>
                                    Signed in as
                                    <span className="gw-accent-color">{auth && getClaims()}</span>
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
    )
}


export default MobileNavHeader;