import RequisitionSearch from '../components/RequisitionSearch';
import { Switch, useRouteMatch, Route} from 'react-router-dom';
import Conversation from '../components/Conversation'
import RequisitionForm from '../components/RequisitionForm';
const HomeRoute = (props)=>{
    let { url, path} = useRouteMatch()
    return(
        <>
        <Route exact path = {path}>
            <RequisitionSearch />
        </Route>
        <Route  path = {`${path}/check/:id`}>
            <RequisitionForm />
        </Route>
        <Route exact path = {`${path}/conversation/:id/:reqUserId`}>
            <Conversation />
        </Route>
        
        </>
    )
}

export default HomeRoute;