import { currencyCodes, truncate } from "../../config/helpers";
import { useState } from "react";
import * as dayjs from "dayjs";
import * as accounting from "accounting";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { sendBackRequisition } from "../../actions/dashboardActions";
import ReactTooltip from "react-tooltip";

import Modal from "react-modal";
import RequisitionForm from "../RequisitionForm";

const modalStyle = {
    content: {
        width: "80vw",
        margin: "0 auto",
        "max-height": "100vh",
        "overflow-y": "auto",
    },
};
const closeModal = {
    backgroundColor: "red",
    color: "white",
    position: "fixed",
    border: "none",
};

const RequisitionItem = (props) => {
    let {
        date,
        user,
        type,
        approvedBy,
        holderCheck,
        checkedBy,
        currency,
        reviewedBy,
        userId: reqUserId,
        status,
        title,
        total,
        id,
        conversations,
        time,
    } = props.reqItem;
    let auth = useSelector(({ auth }) => auth);
    let [isSending, setIsSending] = useState(false);
    let history = useHistory();
    var userId = auth.uid;
    let notcount = conversations
        ? conversations[userId]
            ? parseInt(conversations.count) - parseInt(conversations[userId])
            : conversations.count
        : "";

    // modal controls
    const [modalOpen, setModalOpen] = useState(false);
    const setModalOpenToTrue = () => {
        setModalOpen(true);
    };
    const setModalOpenToFalse = () => {
        setModalOpen(false);
    };
    // end modal ctrl

    let statusLabel = {
        approved: "Approved By",
        holderCheck: "Budget Holder Checked",
        holderChecked: "Budget Holder Checked",
        pending: "status",
        checked: "Finance Checked",
        reviewed: "Reviewer Checked",
        sentBack: "status",
        uploaded: "status",
        paymentMade: "status",
    };
    let statusRender = {
        approved: approvedBy ? approvedBy.name : "",
        holderCheck: holderCheck ? holderCheck.name : "",
        holderChecked: holderCheck ? holderCheck.name : "",
        pending: status,
        checked: checkedBy ? checkedBy.name : "",
        reviewed: reviewedBy ? reviewedBy.name : "",
        sentBack: "Sent Back",
        paymentMade: "Payment made",
        uploaded: "Uploaded For Payment",
    };
    let reqDate = new dayjs(Math.abs(time)).format("D MMM, YY @ h:mm a");
    let curr = currencyCodes[currency];
    let amount = accounting.formatMoney(total, curr, 2);

    const sendBack = async (req, auth) => {
        setIsSending(true);
        await sendBackRequisition(req, auth);
        setIsSending(false);
    };

    if (total === 0 || total === undefined || total === null) {
        return null;
    }

    return (
        <div className="row mt-3 py-3">
            <div className="col-lg-2 mb-4 mb-lg-0 d-flex align-items-center">
                <img
                    width="50"
                    height="50"
                    className="item-avatar mr-3 ml-2"
                    alt="user"
                    src={user && user.photoUrl}
                />
                <h5 className="item-name">{user && user.name}</h5>
            </div>
            <div className="col-lg-3 d-flex align-items-center">
                <div>
                    <p className="date gw-accent-color m-0">{reqDate}</p>
                    <p data-tip={title} className="desc m-0 mt-1">
                        {/* {title.trunc(45)} */}
                        {title && truncate(title, 45)}
                    </p>
                </div>
            </div>
            <div className="col-lg-5  det-container">
                <div className="col">
                    <span>Type</span>
                    <h5 className="check-name mt-1">
                        {type === "Petty Cash" ? "Petty Cash" : "Fund Req"}
                    </h5>
                </div>
                <div className="col">
                    <span>Amount</span>
                    <h5 className="check-name mt-1">{amount}</h5>
                </div>
                <div className="col">
                    <span>{statusLabel[status]}</span>
                    <h5 className="check-name">{statusRender[status]}</h5>
                </div>
            </div>
            <section className="modal-section">
                <Modal
                    className="modal-body"
                    isOpen={modalOpen}
                    ariaHideApp={false}
                    style={modalStyle}
                    onRequestClose={() => setModalOpen(false)}
                >
                    <RequisitionForm
                        id={`${id}`}
                        closeModal={setModalOpenToFalse}
                    />
                </Modal>
            </section>
            <div className="col-lg-2 d-flex align-items-center action-btn-container">
                {type === "Petty Cash" && status === "approved" && (
                    <button
                        type="button"
                        className="action-btn btn btn-gw-circle inner-shadow-sm gw-success-color"
                    >
                        <i className="fas fa-check gw-succes-color"></i>
                    </button>
                )}
                {type !== "Petty Cash" && status === "paymentMade" && (
                    <button
                        type="button"
                        className="action-btn btn btn-gw-circle inner-shadow-sm gw-success-color"
                    >
                        <i className="fas fa-check gw-succes-color"></i>
                    </button>
                )}
                {type !== "Petty Cash" && status !== "paymentMade" && (
                    <button
                        data-tip="View Requisition"
                        className="action-btn btn btn-gw-circle outer-shadow"
                        onClick={setModalOpenToTrue}
                    >
                        <i className="fas fa-eye gw-accent-color"></i>
                    </button>
                )}
                {type === "Petty Cash" && status !== "approved" && (
                    <button
                        data-tip="View Requisition"
                        className="action-btn btn btn-gw-circle outer-shadow"
                        onClick={setModalOpenToTrue}
                    >
                        <i className="fas fa-eye gw-accent-color"></i>
                    </button>
                )}
                {/* {status !== "paymentMade" && status !== "approved" && (
                    <button
                        data-tip="Send Requisitoin back"
                        onClick={async () => {
                            await sendBack(props.reqItem, auth);
                            history.push(
                                `admin/conversation/${props.reqItem.id}`
                            );
                        }}
                        className={`action-btn btn btn-gw-circle outer-shadow ${
                            isSending && "loading disabled"
                        }`}
                    >
                        <i className="fas fa-reply gw-info-color"></i>
                    </button>
                )} */}

                {/* <Link
                    data-tip="Conversations"
                    to={`admin/conversation/${id}/${reqUserId}`}
                    className="action-btn btn btn-gw-circle gw-accent-color outer-shadow"
                >
                    <i className="fas fa-bell"></i>
                    <span className="badge shadow-none">
                        {notcount ? notcount : ""}
                    </span>
                </Link> */}
            </div>
            <ReactTooltip />
        </div>
    );
};

export default RequisitionItem;
