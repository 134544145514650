import { Formik, useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../components/LoadingScreen";
import { Link } from "react-router-dom";
import { fetchBudgets } from "../actions/dashboardActions";
import firebase from "firebase";

const BudgetItem = (props) => {
  let { index, handleChange, budget, id } = props;
  let [mode, setMode] = useState(!budget.code || "view");
  let [isSaving, setIsSaving] = useState(false);
  let { values, setFieldValue } = useFormikContext();
  const save = async () => {
    let budget = values.budgets[index];

    setIsSaving(true);
    await firebase.database().ref(`budgetCodes/${budget.id}`).set(budget);
    setIsSaving(false);
    setMode("view");
  };

  const deleteBudget = async () => {
    let budget = values.budgets.splice(index, 1);
    setIsSaving(true);
    setFieldValue("budgets", values.budgets);
    await firebase.database().ref(`budgetCodes/${budget.id}`).remove();
  };
  return (
    <div className="row outer-shadow-sm my-2">
      <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
        <label className="d-lg-none">Account Number</label>
        <input
          name={`budgets.${index}.code`}
          onChange={handleChange}
          type="text"
          value={budget.code}
          className={`item-input  ${
            mode === "view" ? "disabled" : "inner-shadow"
          }`}
        />
      </div>
      <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
        <label className="d-lg-none">Account Name</label>
        <input
          name={`budgets.${index}.project`}
          onChange={handleChange}
          type="text"
          value={budget.project}
          className={`item-input  ${
            mode === "view" ? "disabled" : "inner-shadow"
          }`}
        />
      </div>
      <div className="col-lg-3 py-3 d-flex flex-column justify-content-center">
        <label className="d-lg-none">Bank Name</label>
        <input
          name={`budgets.${index}.description`}
          onChange={handleChange}
          type="text"
          value={budget.description}
          className={`item-input  ${
            mode === "view" ? "disabled" : "inner-shadow"
          }`}
        />
      </div>
      <div className="col-lg-3 d-lg-flex justify-content-center py-3">
        {mode === "view" ? (
          <button
            type="button"
            onClick={() => setMode("edit")}
            className="btn gw-primary-color btn-md"
          >
            <i className="fas fa-pen"></i>
          </button>
        ) : (
          <button
            type="button"
            onClick={() => save()}
            className={`btn btn-gw-info btn-md ${
              isSaving && "disabled loading"
            }`}
          >
            <i className="fas fa-save"></i>
          </button>
        )}

        <button
          className="btn gw-danger-color btn-md"
          type="button"
          onClick={() => deleteBudget()}
        >
          <i className="fas fa-trash"></i>
        </button>
      </div>
    </div>
  );
};

const BudgetCodes = (props) => {
  let budgets = useSelector(({ budgets }) => budgets);
  let dispatch = useDispatch();
  let [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    try {
      fetchBudgets(dispatch);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);
  const newBudget = () => {
    return {
      code: "",
      description: "",
      project: "",
      id: firebase.database().ref("budgetCodes").push().key,
    };
  };
  return (
    <section className="my-5 py-5 px-2 px-lg-0">
      <div className="d-flex justify-content-end my-5">
        <Link to="/project" className="align-self-end gw-info-color">
          <i className="fas fa-arrow-left gw-info-color"></i> Go Back
        </Link>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5 className="heading-5">Budgets/Codes</h5>
        <input
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          className="inner-shadow-sm search-sm"
          placeholder="Search Description"
        />
      </div>
      {budgets ? (
        <div className="account-list list my-2">
          <Formik
            initialValues={{
              budgets,
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => {
                        values.budgets.push(newBudget());
                        setFieldValue("budgets", values.budgets);
                      }}
                      type="button"
                      className="btn gw-btn-primary btn-md"
                    >
                      Add Budget Item
                    </button>
                  </div>
                  {values.budgets.map(
                    (budget, i) =>
                      budget.description
                        .toLowerCase()
                        .indexOf(searchTerm.toLowerCase()) > -1 && (
                        <BudgetItem
                          budget={budget}
                          index={i}
                          handleChange={handleChange}
                          id={budget.id}
                        />
                      )
                  )}
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => {
                        values.budgets.push(newBudget());
                        setFieldValue("budgets", values.budgets);
                      }}
                      type="button"
                      className="btn gw-btn-primary btn-md"
                    >
                      Add Budget Item
                    </button>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </section>
  );
};

export default BudgetCodes;
