
import { FETCH_REQUISIITONS,CHARTS_CODES, PROJECT_CHARGE, USER_LIST, FETCH_ACCOUNTS, BUDGET_CODES, SYSTEM_STATS } from '../actions/types';


export const projects = (state = null, action)=>{
    switch (action.type) {
        case FETCH_REQUISIITONS:
            return action.payload || false;
    
        default:
            return state
    }
}

export const projectsCharge = (state = null, action) =>{
    switch (action.type) {
        case PROJECT_CHARGE:
            return action.payload || false;
    
        default:
            return state;
    }
}
export const accounts = (state = null, action) =>{
    switch (action.type) {
        case FETCH_ACCOUNTS:
            return action.payload || false;
    
        default:
            return state;
    }
}
export const budgets = (state = null, action) =>{
    switch (action.type) {
        case BUDGET_CODES:
            return action.payload || false;
    
        default:
            return state;
    }
}

export const users = (state = null, action)=>{

    switch (action.type) {
        case USER_LIST:
            return action.payload || false
        default:
            return state
    }

}
export const systemStats = (state = null, action)=>{

    switch (action.type) {
        case SYSTEM_STATS:
            return action.payload || false
        default:
            return state
    }

}
export const chartedCodes = (state = null, action)=>{

    switch (action.type) {
        case CHARTS_CODES:
            return action.payload || false
        default:
            return state
    }

}