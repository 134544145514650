import {AUTH_USER, THEME_SELECT} from '../actions/types';
import {fetchTheme} from '../actions/authActions'

export default  (state =null, action) =>{

    switch (action.type) {
        case AUTH_USER:
            return action.payload || false;
        default:
            return state;
    }

}

export const theme  = (state=null, action)=>{
    switch (action.type) {
        case THEME_SELECT:
            return action.payload || false
        default:
            return fetchTheme();
    }
}