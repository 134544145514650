import Searcher from "./Searcher";
import RequisitionList from "./RequisitionList";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchRequisitions, putBudgetCodes } from "../actions/dashboardActions";
import { FETCH_REQUISIITONS } from "../actions/types";
import { convertArrayToCSV } from "convert-array-to-csv";
import * as dayjs from "dayjs";
import firebase from "firebase";

const RequisitionSearch = (props) => {
    let projects = useSelector(({ projects }) => projects);
    let [filter, setFilter] = useState("all");
    let [period, setPeriod] = useState("all");
    let [startDate, setStartDate] = useState("");
    let [endDate, setEndDate] = useState("");
    let [isLoading, setIsLoading] = useState(false);
    let [prevNext, setPrevNext] = useState({ start: "", prevStart: [] });
    let dispatch = useDispatch();
    const [showFilter, setShowFilter] = useState(false);

    // const [dataObjects, setDataObjects] = useState<Array<{}>>([]);

    const dataObjects = [];
    const convertToCSV = async (dataArr) => {
        let csv = convertArrayToCSV(dataObjects);
        let filename = `gwapp-export.csv`;
        if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
        }
        let data = encodeURI(csv);

        let link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", filename);
        link.click();
    };

    useEffect(() => {
        let dbref = fetchRequisitions(startDate, endDate, period);
        putBudgetCodes();

        try {
            dbref.on("value", (snapshot) => {
                let req = [];
                snapshot.forEach((snap) => {
                    let thereq = snap.val();
                    thereq.id = snap.key;
                    req.push(thereq);

                    for (let r in req) {
                        let time = dayjs(Math.abs(req[r].time)).format(
                            "YYYY/MM/DD"
                        );
                        // console.log(time, req[r].title);
                    }

                    return;

                    for (let i = 0; i < req.length; i++) {
                        let {
                            id,
                            total,
                            title,
                            time,
                            date,
                            currency,
                            name,
                            type,
                            items, //array
                            projectName,
                            checkedBy,
                            checkedDate,
                            approveBy,
                            approveDate,
                            holderCheck,
                            hoderCheckDate,
                            reviewedBy,
                            reviewDate,
                            accountName,
                            accountNumber,
                            bankName,
                            status,
                        } = req[i];
                        const user = name;
                        const project = projectName;
                        id = id.replace(/-/g, "");
                        time
                            ? (time = dayjs(Math.abs(time)).format(
                                  "YYYY/MM/DD"
                              ))
                            : (time = "");
                        date
                            ? (date = dayjs(Math.abs(date)).format(
                                  "YYYY/MM/DD"
                              ))
                            : (date = "");
                        checkedDate = dayjs(Math.abs(checkedDate)).format(
                            "YYYY/MM/DD"
                        );
                        approveDate
                            ? (approveDate = dayjs(
                                  Math.abs(approveDate)
                              ).format("YYYY/MM/DD"))
                            : (approveDate = "");
                        hoderCheckDate
                            ? (hoderCheckDate = dayjs(
                                  Math.abs(hoderCheckDate)
                              ).format("YYYY/MM/DD"))
                            : (hoderCheckDate = "");
                        reviewDate
                            ? (reviewDate = dayjs(Math.abs(reviewDate)).format(
                                  "YYYY/MM/DD"
                              ))
                            : (reviewDate = "");
                        approveBy
                            ? (approveBy = approveBy.name)
                            : (approveBy = "");
                        checkedBy
                            ? (checkedBy = checkedBy.name)
                            : (checkedBy = "");
                        holderCheck
                            ? (holderCheck = holderCheck.name)
                            : (holderCheck = "");
                        reviewedBy
                            ? (reviewedBy = reviewedBy.name)
                            : (reviewedBy = "");
                        let itemsList = "";

                        for (let item in items) {
                            itemsList += items[item].title;
                        }

                        const dataToExport = {
                            id,
                            total,
                            title,
                            time,
                            date,
                            currency,
                            user,
                            type,
                            itemsList,
                            project,
                            checkedBy,
                            checkedDate,
                            approveBy,
                            approveDate,
                            holderCheck,
                            hoderCheckDate,
                            reviewedBy,
                            reviewDate,
                            accountName,
                            accountNumber,
                            bankName,
                            status,
                        };

                        // setDataObjects(prevDataObjects => [...prevDataObjects, dataToExport]);
                        dataObjects.push(dataToExport);
                    }
                });
                console.log("to csv:::", dataObjects);
                // convertToCSV(dataObjects);
                dispatch({ type: FETCH_REQUISIITONS, payload: req });
            });
        } catch (error) {
            console.log("error fetching requisitions");
            console.log(error);
        }
    }, [dispatch, startDate, endDate, period]);

    const periodFilter = () => {
        setIsLoading(true);
        try {
            let dbRef = fetchRequisitions(startDate, endDate, period);
            dbRef.on("value", (snapshot) => {
                let req = [];
                snapshot.forEach((snap) => {
                    let thereq = snap.val();
                    thereq.id = snap.key;
                    req.push(thereq);
                });
                console.log(req);
                setIsLoading(false);
                setPrevNext({ start: "", prevStart: [] });
                dispatch({ type: FETCH_REQUISIITONS, payload: req });
            });
        } catch (error) {
            console.log(error);
        }
    };
    const timelineFilter = () => {
        let newStart = 0 - new Date(startDate).getTime();
        let newEnd = 0 - new Date(endDate).getTime();
        setIsLoading(true);

        try {
            let dbRef = fetchRequisitions(newEnd, newStart, period);
            dbRef.on("value", (snapshot) => {
                let req = [];

                snapshot.forEach((snap) => {
                    let thereq = snap.val();
                    thereq.id = snap.key;
                    req.push(thereq);
                });
                setIsLoading(false);
                setPrevNext({ start: "", prevStart: [] });
                dispatch({ type: FETCH_REQUISIITONS, payload: req });
            });
        } catch (error) {
            console.log(error);
        }
    };

    const prev = () => {
        let history = [...prevNext.prevStart];
        setIsLoading(true);
        try {
            let newStart = history.pop();
            let dbRef = fetchRequisitions(newStart, endDate, period);
            dbRef.on("value", (snapshot) => {
                let req = [];
                snapshot.forEach((snap) => {
                    let thereq = snap.val();
                    thereq.id = snap.key;
                    req.push(thereq);
                });
                console.log(req);
                setIsLoading(false);
                setPrevNext({ start: "", prevStart: history });
                dispatch({ type: FETCH_REQUISIITONS, payload: req });
            });
        } catch (error) {
            console.log(error);
        }
    };
    const next = () => {
        let history = [...prevNext.prevStart, projects[0].time];
        setIsLoading(true);
        try {
            let newStart = projects[projects.length - 1].time;
            let dbRef = fetchRequisitions(newStart, endDate, period);

            dbRef.on("value", (snapshot) => {
                let req = [];
                snapshot.forEach((snap) => {
                    let thereq = snap.val();
                    thereq.id = snap.key;
                    req.push(thereq);
                });
                // console.log(req);

                console.log("checking next btn::");
                for (let r in req) {
                    if (req[r].total === "248000.00") {
                        console.log("found", req[r]);
                    }
                }

                setIsLoading(false);
                setPrevNext({ start: "", prevStart: history });
                dispatch({ type: FETCH_REQUISIITONS, payload: req });
            });
        } catch (error) {
            console.log(error);
        }
    };
    const handleShowFilter = () => {
        setShowFilter(!showFilter);
    };

    return (
        <>
            <div className="row mt-3">
                <div className="col-md-2 pt-3">
                    {showFilter ? (
                        <button
                            className="btn shadow-none border border-success"
                            onClick={handleShowFilter}
                        >
                            Close Filter
                        </button>
                    ) : (
                        <button className="btn" onClick={handleShowFilter}>
                            Search Filter:
                        </button>
                    )}
                </div>
                <div className="col-md-10">
                    {showFilter && (
                        <Searcher
                            isLoading={isLoading}
                            periodFilter={periodFilter}
                            timelineFilter={timelineFilter}
                            filter={filter}
                            period={period}
                            startDate={startDate}
                            endDate={endDate}
                            setEndDate={(e) => {
                                setEndDate(e.target.value);
                            }}
                            setStartDate={(e) => {
                                setStartDate(e.target.value);
                            }}
                            setFilter={(e) => {
                                setFilter(e.target.value);
                            }}
                            setPeriod={(e) => {
                                setPeriod(e.target.value);
                            }}
                        />
                    )}
                </div>
            </div>
            <section className="my-5">
                <h5 className="pt-3 heading-5">Requisitions</h5>
                <RequisitionList
                    projects={projects}
                    isLoading={isLoading}
                    filter={filter}
                />
            </section>
            <div className="d-flex justify-content-between">
                <button
                    type="button"
                    className={`btn btn-gw-secondary outer-shadow-sm ${
                        prevNext.prevStart.length === 0 ? "disabled" : ""
                    }`}
                    onClick={() => prev()}
                >
                    Previous
                </button>
                <button
                    type="button"
                    className={`btn btn-gw-primary outer-shadow-sm ${
                        isLoading && "disabled"
                    }`}
                    onClick={() => next()}
                >
                    Next
                </button>
            </div>
        </>
    );
};

export default RequisitionSearch;
