import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { analyticsSearch } from "../actions/dashboardActions";

import AnalyticsView from "../components/AnalysisView";
import AnalyticsSerchList from "../components/AnalyticsSearchList";
import AnalyticsSearchForm from "../components/subcomponents/AnalyticsSearchForm";

import { fetchRequisitions, putBudgetCodes } from "../actions/dashboardActions";
import { FETCH_REQUISIITONS } from "../actions/types";

const AnalyticsRoute = (props) => {
    let { path } = useRouteMatch();
    let { projectsCharge, users } = useSelector(({ projectsCharge, users }) => {
        return { projectsCharge, users };
    });
    var [projects, setProjects] = useState(null);
    console.log({ projectsCharge });

    let [period, setPeriod] = useState("all");
    let [startDate, setStartDate] = useState("");
    let [endDate, setEndDate] = useState("");

    useEffect(() => {
        async function fetchData() {
            let data = await analyticsSearch({
                start: "",
                user: "",
                end: "",
                project: "",
            });

            setProjects(data.projects);
            console.log("proj data", data);
        }
        fetchData();
    }, []);

    useEffect(() => {
        let dbref = fetchRequisitions(startDate, endDate, period);
        putBudgetCodes();

        try {
            dbref.on("value", (snapshot) => {
                let req = [];
                console.log("from useEffect");
                snapshot.forEach((snap) => {
                    let thereq = snap.val();
                    thereq.id = snap.key;
                    req.push(thereq);
                });
                setProjects(req);
            });
        } catch (error) {
            console.log("error fetching requisitions");
            console.log(error);
        }
    }, [startDate, endDate, period]);

    return (
        <Switch>
            <Route exact path={path}>
                <div>
                    <h3 className="h3 mt-5">Analytics</h3>
                    <AnalyticsSearchForm
                        filterProject={setProjects}
                        projects={projectsCharge}
                        users={users}
                    />
                    <h6 className="mt-4 heading-6">Serch Results </h6>
                    <AnalyticsSerchList projects={projects} perpage={10} />
                </div>
            </Route>
            <Route path={`${path}/view/:id`}>
                <div>
                    <AnalyticsView />
                </div>
            </Route>
        </Switch>
    );
};

export default AnalyticsRoute;
