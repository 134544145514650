import RequisitionItem from "./subcomponents/RequisitionItem";
import LoadingItems from "./subcomponents/LoadingItems";
import { useSelector } from "react-redux";
import { claimTruth } from "../config/helpers";

const RequisitionList = (props) => {
    let { isLoading, filter } = props;

    let { projects, auth } = useSelector(({ projects, auth }) => {
        return { projects, auth };
    });
    let status = filter === "myattention" ? true : false;

    if (!isLoading && projects) {
        let reqs = projects.map((req, i) => {
            if (status) {
                if (claimTruth(auth.claims, req.status))
                    return (
                        <RequisitionItem reqItem={req} key={req.id} index={i} />
                    );
            } else {
                return <RequisitionItem reqItem={req} key={req.id} index={i} />;
            }
        });
        return <div className="activity">{reqs}</div>;
    } else {
        return <LoadingItems />;
    }
};

export default RequisitionList;
