import {useSelector, useDispatch} from 'react-redux'
import {useEffect} from 'react';
import { fetchProjectsChargeTo } from '../../actions/dashboardActions'
import {useFormikContext} from 'formik'


const Projects = props =>{
    let projectsCharge = useSelector(({projectsCharge})=>projectsCharge);
    let dispatch = useDispatch();
    let {setFieldValue, values} = useFormikContext();
    useEffect(()=>{
        fetchProjectsChargeTo(dispatch);
    }, []);

    const handleProjectChange = (e)=>{
        let {bankName, accountNumber, accountName} = projectsCharge[e.target.value].account;
        let projectName= projectsCharge[e.target.value].projectName
        setFieldValue('account', {bankName, accountNumber, accountName, projectName} );
        setFieldValue('projectId',projectsCharge[e.target.value].account.id);
        setFieldValue('projectChargedTo', projectsCharge[e.target.value]);
    }
    
    if(!projectsCharge){
        return(
            <select value = {values.projectChargedTo && values.projectChargedTo.projectName} className="form-control custom-select outer-shadow loading disabled">
                                    <option value="">Please Wait...</option>
            </select>
        )
    }
    else{
        return(
            <select name = "chargedTo" className="form-control custom-select outer-shadow" onChange = {handleProjectChange}>
                <option value = "">Select Project</option>
                {
                    projectsCharge.map((project, i)=>(
                        <option value = {i}>{project.projectName}</option>
                    ))
                }
            </select>
        )
    }  
}


export default Projects;